import {
  IonPage,
  IonContent,
  IonButton,
  IonModal,
  IonChip,
  IonIcon
} from '@ionic/react';
import { COMPARISON_GRID, HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Plans.scss';
import { useHistory } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import {
  hideZipcodeModal,
  resetSignup,
  showZipcodeModal
} from 'features/shared/signup/SignupSlice';
import { gaBtnClickEvent } from 'core/util';
import Header from '../Header';
import Footer from '../Footer';
import AddOns from 'features/shared/billing/AddOns';
import Summary from 'features/shared/billing/Summary';
import {
  hideAddOnsModal,
  hideSubscriptionSummaryModal,
  resetBilling,
  setNewBillingPeriod,
  setNewPlan,
  showAddOnsModal
} from 'features/shared/billing/BillingSlice';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import ArrowRight from 'assets/icons/arrow-right.svg';
import ZipcodeCheck from './ZipcodeCheck';
import { fetchInitialPlans } from '../../SignupActions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';

enum GridPlans {
  DIGITAL = 36,
  AUTO_DELIVERY = 67
}

const Plans: React.FC = () => {
  const history = useHistory();
  let dispatch = useDispatch();

  const { addOnModalVisible, subscriptionSummaryModalVisible } = useSelector(
    (state: RootState) => state.billing
  );
  const { signupPlans } = useSelector((state: RootState) => state.signup);
  const { zipcodeModalVisible } = useSelector(
    (state: RootState) => state.signup
  );
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  const getStarted = (id: number) => {
    const selectedPlan = signupPlans.find((plan: any) => {
      return plan.id === id;
    });
    submitPlanToCheckout(selectedPlan);
  };

  const submitPlanToCheckout = (plan: any) => {
    const period = toggleIndex === 0 ? 'monthly' : 'yearly';
    dispatch(setNewBillingPeriod(period));
    dispatch(setNewPlan(plan));
    gaBtnClickEvent('sign_up_select_plan');
    history.push(`/signup/create`);
  };

  const openAddOnsModal = (plan: any) => {
    const period = toggleIndex === 0 ? 'monthly' : 'yearly';
    dispatch(setNewBillingPeriod(period));
    dispatch(setNewPlan(plan));
    dispatch(showAddOnsModal(plan.addons));
    gaBtnClickEvent('open_addons_modal');
  };

  const openZipcodeModal = () => {
    dispatch(showZipcodeModal());
    gaBtnClickEvent('open_addons_modal');
  };

  const dismissAddOnsModal = () => {
    dispatch(hideAddOnsModal());
  };

  const dismissSubscriptionSummaryModal = () => {
    dispatch(hideSubscriptionSummaryModal());
  };

  const dismissZipcodeModal = () => {
    dispatch(hideZipcodeModal());
    scrollToTop();
  };

  const displayPlanName = (plan: any) => {
    let planName;
    if (
      plan.square_feet_min &&
      plan.square_feet_max &&
      plan.square_feet_max !== 1000000
    ) {
      planName = `${plan.name} ${plan.square_feet_min.toLocaleString(
        'en-US'
      )}-${plan.square_feet_max.toLocaleString('en-US')} sq ft`;
    } else if (plan.square_feet_min && !plan.square_feet_max) {
      planName = `${plan.name} > ${plan.square_feet_min.toLocaleString(
        'en-US'
      )} sq ft`;
    } else if (!plan.square_feet_min && plan.square_feet_max) {
      planName = `${plan.name} < ${plan.square_feet_max.toLocaleString(
        'en-US'
      )} sq ft`;
    } else if (plan.square_feet_max >= 1000000) {
      planName = `${plan.name} ${plan.square_feet_min.toLocaleString(
        'en-US'
      )}+ sq ft`;
    } else {
      planName = plan.name;
    }
    return planName;
  };

  const [toggleIndex, setToggleIndex] = React.useState(0);
  const toggleOptions = ['Monthly', 'Yearly'];
  const contentRef = useRef<HTMLIonContentElement>(null);

  const [width, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function updateScreenWidth() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateScreenWidth);
    return () => window.removeEventListener('resize', updateScreenWidth);
  }, [width]);

  useEffect(() => {
    dispatch(resetSignup());
    dispatch(resetBilling());
    dispatch(fetchInitialPlans());
  }, []);

  function DesktopPlansCardView() {
    const sortedPlans = signupPlans
      .slice()
      .sort((a: any, b: any) => a.id - b.id);
    return (
      <>
        {sortedPlans.map((plan: any, i: number) => {
          return (
            <div className="hk-signup-plan-card" key={'plan-' + i}>
              <div
                className={`hk-promo-banner no-promo`}
                style={{
                  backgroundColor: plan.name.includes('Full Service')
                    ? 'var(--hk-color-spock)'
                    : 'var(--hk-color-enterprise)'
                }}
              >
                {''}
              </div>
              <div
                className={`hk-subscription-plan ${
                  toggleIndex === 0 ? 'left' : 'right'
                }`}
              >
                <div>
                  <div className="hk-subscription-plan-title">
                    <h6>{displayPlanName(plan)}</h6>
                  </div>
                  <div className="hk-subscription-plan-cost">
                    <span className="hk-subscription-plan-cost-amount">
                      {!!plan.pricing.monthly && (
                        <>
                          {toggleIndex === 0 && (
                            <>
                              ${plan.pricing.monthly.usd}
                              <span className="hk-subscription-plan-cost-amount__label">
                                /Month
                              </span>
                            </>
                          )}
                          {toggleIndex === 1 && (
                            <>
                              ${plan.pricing.yearly.usd}
                              <span className="hk-subscription-plan-cost-amount__label">
                                /Year
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                  <hr
                    style={{
                      borderBottom: plan.name.includes('Full Service')
                        ? '1px solid var(--hk-color-spock)'
                        : '1px solid var(--hk-color-enterprise)'
                    }}
                  />
                  <div className="hk-subscription-plan-description">
                    {plan.description}
                  </div>
                  <div className="hk-subscription-plan-list">
                    <ul style={{ listStyleType: 'square' }}>
                      {plan.bullets.map((bullet: any, i: number) => (
                        <li key={`bullet-${i}`}> {bullet}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div>
                  <IonButton
                    expand="block"
                    color={
                      plan.name.includes('Full Service')
                        ? 'secondary'
                        : 'primary'
                    }
                    strong={true}
                    className="hk-subscription-plan-plans-button"
                    onClick={() => submitPlanToCheckout(plan)}
                  >
                    Choose Plan
                  </IonButton>
                  <div
                    className="hk-subscription-plan-add-ons"
                    style={{
                      color: plan.name.includes('Full Service')
                        ? 'var(--hk-color-spock)'
                        : 'var(--hk-color-enterprise)'
                    }}
                    onClick={() => openAddOnsModal(plan)}
                  >
                    See add-ons
                    <IonIcon
                      color={
                        plan.name.includes('Full Service')
                          ? 'secondary'
                          : 'primary'
                      }
                      icon={ArrowRight}
                    ></IonIcon>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {signupPlans.every(
          (plan: any) => !plan.name.includes('Full Service')
        ) && (
          <>
            <div className="hk-full-signup-plan-card">
              <div className={`hk-promo-banner no-promo`}>{''}</div>
              <div
                className={`hk-subscription-plan ${
                  toggleIndex === 0 ? 'left' : 'right'
                }`}
              >
                <div>
                  <div className="hk-subscription-plan-title">
                    <h6>Full Service Sign Up</h6>
                  </div>
                  <div className="hk-subscription-plan-cost">
                    <span className="hk-subscription-plan-cost-amount">
                      <>
                        {toggleIndex === 0 && (
                          <>
                            $59
                            <span className="hk-subscription-plan-cost-amount__label">
                              /Month
                            </span>
                          </>
                        )}
                        {toggleIndex === 1 && (
                          <>
                            $699
                            <span className="hk-subscription-plan-cost-amount__label">
                              /Year
                            </span>
                          </>
                        )}
                      </>
                    </span>
                  </div>
                  <hr />
                  <div className="hk-subscription-plan-description">
                    Digital access with twice annual professional maintenance
                    service and installed replacement products
                  </div>
                  <div className="hk-subscription-plan-list">
                    <h4>Interested?</h4>
                    <p>
                      Use our check availability to see if service is available
                      in your area
                    </p>
                  </div>
                </div>

                <div>
                  <IonButton
                    expand="block"
                    strong={true}
                    color="secondary"
                    className="hk-subscription-plan-button"
                    onClick={openZipcodeModal}
                  >
                    Check Availability
                  </IonButton>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  function MobilePlansCardView() {
    const sortedPlans = signupPlans
      .slice()
      .sort((a: any, b: any) => a.id - b.id);
    return (
      <Swiper
        className="hk-plan-slider"
        navigation={true}
        pagination={true}
        modules={[Navigation, Pagination]}
      >
        {sortedPlans.map((plan: any, i: number) => {
          return (
            <>
              <SwiperSlide key={`slide-${i}`}>
                <div className="hk-signup-plan-card" key={'plan-' + i}>
                  <div
                    className={`hk-promo-banner no-promo`}
                    style={{
                      backgroundColor: plan.name.includes('Full Service')
                        ? 'var(--hk-color-spock)'
                        : 'var(--hk-color-enterprise)'
                    }}
                  >
                    {''}
                  </div>
                  <div
                    className={`hk-subscription-plan ${
                      toggleIndex === 0 ? 'left' : 'right'
                    }`}
                  >
                    <div>
                      <div className="hk-subscription-plan-title">
                        <h6>{displayPlanName(plan)}</h6>
                      </div>
                      <div className="hk-subscription-plan-cost">
                        <span className="hk-subscription-plan-cost-amount">
                          {!!plan.pricing.monthly && (
                            <>
                              {toggleIndex === 0 && (
                                <>
                                  ${plan.pricing.monthly.usd}
                                  <span className="hk-subscription-plan-cost-amount__label">
                                    /Month
                                  </span>
                                </>
                              )}
                              {toggleIndex === 1 && (
                                <>
                                  ${plan.pricing.yearly.usd}
                                  <span className="hk-subscription-plan-cost-amount__label">
                                    /Year
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </span>
                      </div>
                      <hr
                        style={{
                          borderBottom: plan.name.includes('Full Service')
                            ? '1px solid var(--hk-color-spock)'
                            : '1px solid var(--hk-color-enterprise)'
                        }}
                      />
                      <div className="hk-subscription-plan-description">
                        {plan.description}
                      </div>
                      <div className="hk-subscription-plan-list">
                        <ul style={{ listStyleType: 'square' }}>
                          {plan.bullets.map((bullet: any, i: number) => (
                            <li key={`bullet-${i}`}> {bullet}</li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div>
                      <IonButton
                        expand="block"
                        color={
                          plan.name.includes('Full Service')
                            ? 'secondary'
                            : 'primary'
                        }
                        strong={true}
                        className="hk-subscription-plan-plans-button"
                        onClick={() => submitPlanToCheckout(plan)}
                      >
                        Choose Plan
                      </IonButton>
                      <div
                        className="hk-subscription-plan-add-ons"
                        style={{
                          color: plan.name.includes('Full Service')
                            ? 'var(--hk-color-spock)'
                            : 'var(--hk-color-enterprise)'
                        }}
                        onClick={() => openAddOnsModal(plan)}
                      >
                        See add-ons
                        <IonIcon
                          color={
                            plan.name.includes('Full Service')
                              ? 'secondary'
                              : 'primary'
                          }
                          icon={ArrowRight}
                        ></IonIcon>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </>
          );
        })}
        {signupPlans.every(
          (plan: any) => !plan.name.includes('Full Service')
        ) && (
          <SwiperSlide>
            <div className="hk-full-signup-plan-card">
              <div className={`hk-promo-banner no-promo`}>{''}</div>
              <div
                className={`hk-subscription-plan ${
                  toggleIndex === 0 ? 'left' : 'right'
                }`}
              >
                <div>
                  <div className="hk-subscription-plan-title">
                    <h6>Full Service Sign Up</h6>
                  </div>
                  <div className="hk-subscription-plan-cost">
                    <span className="hk-subscription-plan-cost-amount">
                      <>
                        {toggleIndex === 0 && (
                          <>
                            $59
                            <span className="hk-subscription-plan-cost-amount__label">
                              /Month
                            </span>
                          </>
                        )}
                        {toggleIndex === 1 && (
                          <>
                            $699
                            <span className="hk-subscription-plan-cost-amount__label">
                              /Year
                            </span>
                          </>
                        )}
                      </>
                    </span>
                  </div>
                  <hr />
                  <div className="hk-subscription-plan-description">
                    Digital access with twice annual professional maintenance
                    service and installed replacement products
                  </div>
                  <div className="hk-subscription-plan-list">
                    <h4>Interested?</h4>
                    <p>
                      Use our check availability to see if service is available
                      in your area
                    </p>
                  </div>
                </div>

                <div>
                  <IonButton
                    expand="block"
                    strong={true}
                    color="secondary"
                    className="hk-subscription-plan-button"
                    onClick={openZipcodeModal}
                  >
                    Check Availability
                  </IonButton>
                </div>
              </div>
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    );
  }

  function ComparePlansDesktopView() {
    return (
      <section className="hk-desktop-compare-plans-container">
        <div className="hk-desktop-compare-plans">
          <div className="hk-desktop-compare-plans-title">
            <h1>Compare Our Plans</h1>
            <h4>
              Choose the right subscription for you, discounts for yearly
              subscriptions available
            </h4>
          </div>

          <div className="hk-desktop-compare-plans-grid">
            <div className="hk-desktop-compare-plans-grid-row-wrapper">
              <div className="grid-header">
                <h2>Details</h2>
              </div>
              <div className="grid-header">
                <h2>Digital</h2>
              </div>
              <div className="grid-header">
                <h2>Auto Delivery</h2>
              </div>
              <div className="grid-header">
                <h2>Full Service</h2>
              </div>
            </div>

            <div className="hk-desktop-compare-plans-grid-row-wrapper">
              <div className="grid-pricing">
                <h2>Price</h2>
              </div>
              <div className="grid-pricing">
                <h2>$1.99/month</h2>
              </div>
              <div className="grid-pricing">
                <span>Starting at</span>
                <h2>$26/month</h2>
              </div>
              <div className="grid-pricing">
                <span>Starting at</span>
                <h2>$59/month</h2>
              </div>
            </div>

            {COMPARISON_GRID.map((item, i) => {
              return (
                <div
                  className="hk-desktop-compare-plans-grid-row-wrapper"
                  key={'grid-item-' + i}
                >
                  <div className="grid-data">
                    <h4>{item.title}</h4>
                  </div>
                  <div className="grid-data">
                    <IonIcon icon={item.digital}></IonIcon>
                  </div>
                  <div className="grid-data">
                    <IonIcon icon={item.autoDelivery}></IonIcon>
                  </div>
                  <div className="grid-data">
                    <IonIcon icon={item.fullService}></IonIcon>
                  </div>
                </div>
              );
            })}

            <div className="hk-desktop-compare-plans-grid-row-wrapper">
              <div className="grid-button"></div>
              <div className="grid-button">
                <IonButton
                  expand="block"
                  strong={true}
                  onClick={() => getStarted(GridPlans.DIGITAL)}
                >
                  Get Started
                </IonButton>
              </div>
              <div className="grid-button">
                <IonButton
                  expand="block"
                  strong={true}
                  onClick={() => getStarted(GridPlans.AUTO_DELIVERY)}
                >
                  Get Started
                </IonButton>
              </div>
              <div className="grid-button">
                <IonButton
                  color="secondary"
                  expand="block"
                  strong={true}
                  onClick={openZipcodeModal}
                >
                  Check Availability
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function ComparePlansMobileView() {
    return (
      <section className="hk-compare-plans-container">
        <div className="hk-compare-plans">
          <div className="hk-compare-plans-title">
            <h1>Compare Our Plans</h1>
            <h4>
              Choose the right subscription for you, discounts for yearly
              subscriptions available
            </h4>
          </div>

          <div className="hk-compare-plans-grid">
            <div className="grid-header">
              <h2>Digital</h2>
            </div>
            <div className="grid-row">
              <div className="grid-row-data">
                <h4>
                  <strong>Price</strong>
                </h4>
              </div>
              <div className="grid-row-data">
                <strong>$1.99/month</strong>
              </div>
            </div>
            {COMPARISON_GRID.map((item, i) => {
              return (
                <>
                  {i < 6 && (
                    <div className="grid-row" key={'grid-item-' + i}>
                      <div className="grid-row-data">
                        <h4>{item.title}</h4>
                      </div>
                      <div className="grid-row-data">
                        <IonIcon icon={item.digital}></IonIcon>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
            <div className="grid-button">
              <IonButton
                expand="block"
                strong={true}
                onClick={() => getStarted(GridPlans.DIGITAL)}
              >
                Get Started
              </IonButton>
            </div>
          </div>

          <div className="hk-compare-plans-grid">
            <div className="grid-header">
              <h2>Auto Delivery</h2>
            </div>
            <div className="grid-row">
              <div className="grid-row-data">
                <h4>
                  <strong>Price</strong>
                </h4>
              </div>
              <div className="grid-row-data">
                Starting at <strong>$26/month</strong>
              </div>
            </div>
            {COMPARISON_GRID.map((item, i) => {
              return (
                <>
                  {i < 6 && (
                    <div className="grid-row" key={'grid-item-' + i}>
                      <div className="grid-row-data">
                        <h4>{item.title}</h4>
                      </div>
                      <div className="grid-row-data">
                        <IonIcon icon={item.autoDelivery}></IonIcon>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
            <div className="grid-button">
              <IonButton
                expand="block"
                strong={true}
                onClick={() => getStarted(GridPlans.AUTO_DELIVERY)}
              >
                Get Started
              </IonButton>
            </div>
          </div>

          <div className="hk-compare-plans-grid">
            <div className="grid-header">
              <h2>Full Service</h2>
            </div>
            <div className="grid-row">
              <div className="grid-row-data">
                <h4>
                  <strong>Price</strong>
                </h4>
              </div>
              <div className="grid-row-data">
                Starting at <strong>$59/month</strong>
              </div>
            </div>
            {COMPARISON_GRID.map((item, i) => {
              return (
                <>
                  {i < 6 && (
                    <div className="grid-row" key={'grid-item-' + i}>
                      <div className="grid-row-data">
                        <h4>{item.title}</h4>
                      </div>
                      <div className="grid-row-data">
                        <IonIcon icon={item.fullService}></IonIcon>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
            <div className="grid-button">
              <IonButton
                color="secondary"
                expand="block"
                strong={true}
                onClick={openZipcodeModal}
              >
                Check Availability
              </IonButton>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function DesktopPlansView() {
    return (
      <IonPage>
        <Header />
        <IonContent
          ref={contentRef}
          scrollEvents={true}
          className="hk-desktop-plans-view"
        >
          <div className="hk-desktop-plans-header-container">
            <div className="hk-desktop-plans-header">
              <section>
                <h1>Monthly and Yearly Plans Available</h1>
                <h4>
                  Choose a plan to get started on your path to better home
                  maintenance today
                </h4>
              </section>
              <section>
                <div className="hk-desktop-plans-toggle-container">
                  <div className="hk-desktop-plans-toggle">
                    {toggleOptions.map((opt, i) => {
                      return (
                        <IonChip
                          key={'chip-' + i}
                          className={toggleIndex === i ? 'selected' : ''}
                          onClick={() => {
                            setToggleIndex(i);
                          }}
                        >
                          {opt}
                        </IonChip>
                      );
                    })}
                  </div>
                </div>
              </section>
              <p>*discounts for yearly subscriptions</p>
            </div>
          </div>

          <div className="hk-desktop-plans-body">
            <SwitchTransition>
              <CSSTransition
                key={toggleIndex ? 'Monthly' : 'Yearly'}
                addEndListener={(node, done) =>
                  node.addEventListener('transitionend', done, false)
                }
                classNames="toggle"
              >
                <div
                  className={`hk-desktop-plans ${
                    toggleIndex === 0 ? 'left' : 'right'
                  }`}
                >
                  <DesktopPlansCardView />
                </div>
              </CSSTransition>
            </SwitchTransition>
            {ComparePlansDesktopView()}
          </div>
          <Footer darkMode={true} />
        </IonContent>
      </IonPage>
    );
  }

  function MobilePlansView() {
    return (
      <IonPage>
        <Header />
        <IonContent
          ref={contentRef}
          scrollEvents={true}
          fullscreen
          className="hk-signup-plans"
        >
          <div className="hk-signup-plans-body">
            <div className="hk-signup-plans-header-container">
              <div className="hk-signup-plans-header">
                <section>
                  <h1>Monthly and Yearly Plans Available</h1>
                  <h4>
                    Choose a plan to get started on your path to better home
                    maintenance today
                  </h4>
                </section>
                <section>
                  <div className="hk-signup-plans-toggle-container">
                    <div className="hk-signup-plans-toggle">
                      {toggleOptions.map((opt, i) => {
                        return (
                          <IonChip
                            key={'chip-' + i}
                            className={toggleIndex === i ? 'selected' : ''}
                            onClick={() => {
                              setToggleIndex(i);
                            }}
                          >
                            {opt}
                          </IonChip>
                        );
                      })}
                    </div>
                  </div>
                </section>
                <p>*discounts for yearly subscriptions</p>
              </div>
            </div>

            <SwitchTransition>
              <CSSTransition
                key={toggleIndex ? 'Monthly' : 'Yearly'}
                addEndListener={(node, done) =>
                  node.addEventListener('transitionend', done, false)
                }
                classNames="toggle"
              >
                <div className={toggleIndex === 0 ? 'left' : 'right'}>
                  <MobilePlansCardView />
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
          {ComparePlansMobileView()}
          <Footer darkMode={true} />
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-plans-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopPlansView()
        : MobilePlansView()}
      <IonModal
        className={
          platformType === HKPlatform.DESKTOP && isDesktopWidth
            ? 'hk-desktop-addon-modal'
            : 'hk-plan-addon-modal'
        }
        isOpen={addOnModalVisible}
        onDidDismiss={dismissAddOnsModal}
      >
        <AddOns />
      </IonModal>

      <IonModal
        className="hk-subscription-summary-modal"
        isOpen={subscriptionSummaryModalVisible}
        onDidDismiss={dismissSubscriptionSummaryModal}
      >
        <Summary />
      </IonModal>
      <IonModal
        className="hk-zipcode-modal"
        isOpen={zipcodeModalVisible}
        onDidDismiss={dismissZipcodeModal}
      >
        <ZipcodeCheck />
      </IonModal>
    </IonPage>
  );
};

export default Plans;
