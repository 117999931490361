import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentType, SearchedDocument, DocumentCount } from 'core/types';

export enum HKDocumentType {
  Photos = 'photos',
  Manuals = 'manuals'
}

export interface Document {
  file_url: string;
  id: number;
  name: string;
  type: HKDocumentType;
  type_id: number;
}

interface DocumentState {
  loading: boolean;
  counts: DocumentCount[] | null;
  documents: Document[] | null;
  searchedDocuments: SearchedDocument[];
  types: DocumentType[];
  currentType: string | null;
  selectedDocument: SearchedDocument | null;
  error: string | null;
  addModalVisible: boolean;
  editDesktopModalVisible: boolean;
  editActionsModalVisible: boolean;
  filesUploading: boolean;
}

export const initialState: DocumentState = {
  loading: false,
  counts: null,
  documents: null,
  searchedDocuments: [],
  types: [],
  currentType: null,
  selectedDocument: null,
  error: null,
  addModalVisible: false,
  editDesktopModalVisible: false,
  editActionsModalVisible: false,
  filesUploading: false
};

const ExcludedDocumentTypes = ['default', 'document', 'photos', 'video_thumb'];

export const documentSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    fetchDocumentTypesSuccess: (state, action: PayloadAction<any>) => {
      const { items } = action.payload;
      const availableTypes = items.filter(
        (type: any) => !ExcludedDocumentTypes.includes(type.field)
      );
      state.types = availableTypes;
    },
    fetchDocumentTypesError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    fetchDocumentCountStart: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    fetchDocumentCountSuccess: (state, action: PayloadAction<any>) => {
      const { items } = action.payload;
      state.counts = items.filter(
        (group: any) => !ExcludedDocumentTypes.includes(group.type)
      );
      state.loading = false;
    },
    fetchDocumentCountError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchDocumentsStart: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    fetchDocumentsSuccess: (state, action: PayloadAction<any>) => {
      const { items } = action.payload;
      state.documents = items;
      state.loading = false;
    },
    fetchDocumentsError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchSearchedDocumentsStart: (state) => {
      state.loading = true;
    },
    fetchSearchedDocumentsSuccess: (state, action: PayloadAction<any>) => {
      state.searchedDocuments = action.payload.items;
      state.loading = false;
    },
    fetchSearchedDocumentsError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetSearchedDocuments: (state) => {
      state.searchedDocuments = [];
    },
    createDocumentStart: (state) => {},
    createDocumentSuccess: (state) => {},
    createDocumentError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateDocumentStart: (state) => {
      state.loading = true;
    },
    updateDocumentSuccess: (state) => {
      state.loading = false;
    },
    updateDocumentError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    deleteDocumentStart: (state) => {
      state.loading = true;
    },
    deleteDocumentSuccess: (state) => {
      state.loading = false;
    },
    deleteDocumentError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setCurrentDocumentType: (state, action: PayloadAction<string | null>) => {
      state.currentType = action.payload;
    },
    setSelectedDocument: (
      state,
      action: PayloadAction<SearchedDocument | null>
    ) => {
      state.selectedDocument = action.payload;
    },
    showDocumentsAddModal: (state) => {
      state.addModalVisible = true;
    },
    hideDocumentsAddModal: (state) => {
      state.addModalVisible = false;
    },
    showDesktopDocumentEditModal: (state) => {
      state.editDesktopModalVisible = true;
    },
    hideDesktopDocumentEditModal: (state) => {
      state.editDesktopModalVisible = false;
    },
    showDocumentEditActionsModal: (state) => {
      state.editActionsModalVisible = true;
    },
    hideDocumentEditActionsModal: (state) => {
      state.editActionsModalVisible = false;
    },
    setFilesUploading: (state, action: PayloadAction<boolean>) => {
      state.filesUploading = action.payload;
    },
    resetDocuments: (state) => {
      state.documents = null;
      state.loading = true;
    },
    resetAllDocuments: () => initialState
  }
});

export const {
  fetchDocumentTypesSuccess,
  fetchDocumentTypesError,
  fetchDocumentCountStart,
  fetchDocumentCountSuccess,
  fetchDocumentCountError,
  fetchDocumentsStart,
  fetchDocumentsSuccess,
  fetchDocumentsError,
  fetchSearchedDocumentsStart,
  fetchSearchedDocumentsSuccess,
  fetchSearchedDocumentsError,
  resetSearchedDocuments,
  createDocumentStart,
  createDocumentSuccess,
  createDocumentError,
  updateDocumentStart,
  updateDocumentSuccess,
  updateDocumentError,
  deleteDocumentStart,
  deleteDocumentSuccess,
  deleteDocumentError,
  setCurrentDocumentType,
  setSelectedDocument,
  showDocumentsAddModal,
  hideDocumentsAddModal,
  showDesktopDocumentEditModal,
  hideDesktopDocumentEditModal,
  showDocumentEditActionsModal,
  hideDocumentEditActionsModal,
  setFilesUploading,
  resetDocuments,
  resetAllDocuments
} = documentSlice.actions;

export default documentSlice.reducer;
