import AvidWarrantyIcon from 'assets/icons/aw-warranty.svg';
import HomeIcon from 'assets/icons/home.svg';
import InventoryIcon from 'assets/icons/inventory.svg';
import ContactsIcon from 'assets/icons/contacts.svg';
import DocumentsIcon from 'assets/icons/documents.svg';
import UserIcon from 'assets/icons/user.svg';
import Washer from 'assets/images/washer.jpg';
import Dryer from 'assets/images/dryer.jpg';
import Furnace from 'assets/images/furnace.jpg';
import Hvac from 'assets/images/hvac.jpg';
import Humidifier from 'assets/images/humidifier.jpg';
import Refrigerator from 'assets/images/refrigerator.jpg';
import WaterSoftener from 'assets/images/water-softener.jpg';
import SmokeDetector from 'assets/images/smoke-detector.jpg';
import CarbonMonoxide from 'assets/images/carbon-monoxide.jpg';
import RadonDetection from 'assets/images/radon-detection.jpg';
import FireExtinguisher from 'assets/images/fire-extinguisher.jpg';
import GarbageDisposal from 'assets/images/garbage-disposal.jpg';
import BathFan from 'assets/images/bath-fan.jpg';
import Range from 'assets/images/range.jpg';
import Dishwasher from 'assets/images/dishwasher.jpg';
import Freezer from 'assets/images/freezer.jpg';
import WaterHeater from 'assets/images/water-heater.jpg';
import ElectricalBreakers from 'assets/images/electrical-breakers.jpg';
import GuttersDownspouts from 'assets/images/gutters-downspouts.jpg';
import Fireplace from 'assets/images/fireplace.jpg';
import GarageDoor from 'assets/images/garage-door.jpg';
import GarageOpener from 'assets/images/garage-opener.jpg';
import AirConditioner from 'assets/images/air-conditioner.jpg';
import SumpPump from 'assets/images/sump-pump.jpg';
import Doors from 'assets/images/doors.jpg';
import Windows from 'assets/images/windows.jpg';
import WaterValves from 'assets/images/water-valves.jpg';
import Roof from 'assets/images/roof.jpg';
import Cladding from 'assets/images/cladding.jpg';
import CeilingFan from 'assets/images/ceiling-fan.jpg';
import WaterFilterSystem from 'assets/images/water-filter-system.jpg';
import Ductwork from 'assets/images/ductwork.jpg';
import Drains from 'assets/images/drains.jpg';
import HoseWaterlines from 'assets/images/hose-waterlines.jpg';
import SealsCaulking from 'assets/images/seals-caulking.jpg';
import Faucets from 'assets/images/faucets.jpg';
import BuildingComponents from 'assets/images/building-components.jpg';
import DoorsWindows from 'assets/images/doors-windows.jpg';
import SmartDevices from 'assets/images/smart-devices.jpg';
import ProjectsWinter from 'assets/images/projects-winter.jpg';
import ProjectsSpring from 'assets/images/projects-spring.jpg';
import Projects from 'assets/images/projects.jpg';
import PestControl from 'assets/images/pest-control.jpg';
import Mattresses from 'assets/images/mattresses.jpg';
import Landscaping from 'assets/images/landscaping.jpg';
import GardenHose from 'assets/images/garden-hose.jpg';
import GarageMaintenance from 'assets/images/garage-maintenance.jpg';
import Concrete from 'assets/images/concrete.jpg';
import Other from 'assets/images/item_card.jpg';
import Oven from 'assets/images/oven.jpg';
import Microwave from 'assets/images/microwave.jpg';
import Hood from 'assets/images/hood.jpg';
import SolarPanel from 'assets/images/solar-panel.jpg';
import HomeCosmetics from 'assets/images/home-cosmetics.jpg';
import HomeHealthSafety from 'assets/images/home-health-safety.jpg';
import { ContactInfo } from './types';
import Close from 'assets/icons/close.svg';
import Check from 'assets/icons/check.svg';

export const firebaseConfig = {
  apiKey: String(process.env.REACT_APP_FIREBASE_API_KEY!),
  authDomain: String(process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!),
  projectId: String(process.env.REACT_APP_FIREBASE_PROJECT_ID!),
  storageBucket: String(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!),
  messagingSenderId: String(
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!
  ),
  appId: String(process.env.REACT_APP_FIREBASE_APP_ID!),
  measurementId: String(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!)
};

// Environments
export const LOCAL = 'locl';
export const DEVELOPMENT = 'devl';
export const STAGING = 'stag';
export const PRODUCTION = 'prod';

// Numeric Values
export const BASE_REM_VAL = 16;
export const HALF_SECOND = 500;
export const ONE_SECOND = 1000;
export const TWO_SECONDS = 2000;

// Database Type IDs
export const DEFAULT_HK_BUILDER_ID = 1;
export const ASSET_TYPE_BATH = 1;
export const ASSET_TYPE_CONTACT = 4;
export const ASSET_TYPE_PAINT = 5;

// Intent
export const PRIMARY = 'primary';
export const SUCCESS = 'success';
export const WARNING = 'warning';
export const DANGER = 'danger';
export const DARK = 'dark';
export const LIGHT = 'light';
export const NONE = 'none';

// Date Formats
export const DATE_FORMAT_MONTH_DAY_YEAR = 'MM/dd/yyyy';
export const DATE_FORMAT_MONTH_DATE = 'MMM d, yyyy';
export const DATE_FORMAT_WEEKDAY_DATE = 'EEEE MMM d, yyyy';
export const DATE_FORMAT_HOUR_MINUTES = 'h:mma';
export const DATE_FORMAT_SHORT_MONTH = 'MMM';
export const DATE_FORMAT_FULL_MONTH = 'MMMM';
export const DATE_FORMAT_FULL_MONTH_YEAR = 'MMMM yyyy';
export const DATE_FORMAT_DATE_TIMESTAMP = 'yyyy-MM-dd';
export const DATE_FORMAT_DATE_NUM = 'd';
export const DATE_FORMAT_DATE_DAY = 'EEE';

// Strings
export const HEX = 'Hex';
export const PUSH_NOTIFICATIONS_NOTAVAILABLE =
  'PUSH_NOTIFICATIONS_NOTAVAILABLE';
export const PUSH_NOTIFICATIONS_DENIED = 'PUSH_NOTIFICATIONS_DENIED';

// Static Contact Type ID
export const HOMEKEEP_CONTACT_ID = 1;
export const HOMEKEEP_CONTACT_TYPE_ID = 37;

// API URLs
// export const HOMEKEEP_URL_SCHEME          = 'homekeep://';
export const HOMEKEEP_URL_MAGIC_CODE = 'https://homekeep.com/auth/mobile?code=';
export const API_HEARTBEAT_URL = '/heartbeat';
export const LOGIN_URL = '/auth/code?email={email}&reset=False';
export const AUTH_URL = '/auth';
export const AUTH_FIREBASE_URL = '/auth/firebase';
export const HOME_URL = '/home/{home_id}';
export const USER_RESET_PASSWORD_URL = '/user/reset_password/{token}';
export const USER_URL = '/user/me';
export const USER_CONTACT_URL = '/user/me/contact';
export const USER_NOTIFICATION_TOKEN_URL = '/user/me/token';
export const APPOINTMENT_LIST_URL =
  '/home/{home_id}/appointments?sort=scheduled&order=desc';
export const APPOINTMENT_DETAILS_URL = '/home/{home_id}/appointment/{appt_id}';
export const APPOINTMENT_TASK_LIST_URL =
  '/home/{home_id}/appointment/{appt_id}/appointment_tasks?sort=sequence';
export const APPOINTMENT_PROPOSALS_URL = '/home/{home_id}/schedule/{hash}';
export const APPOINTMENT_RESCHEDULE_URL =
  '/home/{home_id}/appointment/{appointment_id}/reschedule';
export const UPCOMING_VISITS_URL = '/home/{home_id}/schedule/upcoming';
export const CONTACT_LIST_URL = '/home/{home_id}/contacts';
export const CONTACT_URL = '/home/{home_id}/contact/{contact_id}';
export const DOCUMENT_COUNT_URL = '/home/{home_id}/documents/count';
export const PRODUCT_GROUP_COUNT_URL = '/home/{home_id}/product_group/count';
export const PRODUCT_GROUP_URL = '/home/{home_id}/product_group/{group_id}';
export const PRODUCT_DETAILS_URL = '/home/{home_id}/product/{item_id}';
export const ITEM_REGISTER_URL = '/home/{home_id}/product/{item_id}/register';
export const REGISTER_ALL_URL = '/home/{home_id}/register_all';
export const DEREGISTER_ALL_URL = '/home/{home_id}/deregister_all';
export const ASSET_URL = '/home/{home_id}/asset/{asset_id}';
export const ZENDESK_AUTH_URL = '/auth/zendesk?return_to={return_to}';
export const STATUS_TAG_LIST_URL = '/data/status_tags';
export const SUBSCRIPTION_URL = '/home/{home_id}/subscription';
export const SUBSCRIPTION_PLANS_URL = '/home/{home_id}/subscription_plans';
export const ZENDESK_CHAT_URL =
  'https://v2.zopim.com/widget/popout.html?key=Ztfpf39vP3wBg5WsstfJSIFycEQ1tSgi&lang=us';
export const ZENDESK_HELP_URL = 'https://homekeep.zendesk.com/hc/en-us';
export const PUSH_NOTIFICATIONS_LOG_URL = '/log/push/{uuid}';
export const INITIAL_SIGN_IN_URL = `/auth/reset_password?full_address=\"{full_address}\"`;
export const INITIAL_SIGN_IN_PASSWORD_RESET_URL = `/auth/reset_password`;

// Digital App Constants
export const UPCOMING_TASKS_URL = '/home/{home_id}/tasks/upcoming';
export const OVERDUE_TASKS_URL = '/home/{home_id}/tasks/overdue';
export const FINISHED_TASKS_URL = '/home/{home_id}/tasks/finished';
export const CATALOG_TASKS_URL = '/home/{home_id}/tasks/catalog';
export const DIGITAL_TASK_URL = '/home/{home_id}/diy_task/{task_id}';
export const PRODUCT_TYPE_LIST_URL = '/datatype/product_templates';
export const PRODUCT_TYPE_DETAIL_URL =
  '/datatype/product_template/{product_type_id}';
export const PRODUCT_URL = '/home/{home_id}/products';
export const PRODUCT_SEARCH_URL = '/home/{home_id}/products?term={term}';
export const PRODUCT_TYPE_TOGGLES = '/home/{home_id}/product_types/toggles';
export const PRODUCT_TYPE_TOGGLE =
  '/home/{home_id}/product_types/toggles/{type_id}';
export const PRODUCT_GROUP_LIST_URL = '/data/product_type_groups';
export const DOCUMENT_TYPES_URL = '/datatype/assets';
export const DOCUMENTS_URL = '/home/{home_id}/documents';
export const DOCUMENTS_SEARCH_URL = '/home/{home_id}/documents?term={term}';
export const DOCUMENTS_LIST_URL = '/home/{home_id}/documents/{type}';
export const DOCUMENT_URL = '/home/{home_id}/document/{asset_id}';

export const WARRANTY_BASE = String(process.env.REACT_APP_WARRANTY_BASE!);

// Signup App Constants
export const APP_STORE_URL =
  'https://apps.apple.com/us/app/homekeep/id1562763274';
export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.avidratings.homekeep';

export const RECURLY_PLAN_CODE_FORMAT =
  'pl_{plan}_s_{size}_mt_{tier}_st_{stories}_bp_{billing}';
export const RECURLY_PURCHASE_PLAN_URL_BASE = `https://homekeep.recurly.com/subscribe/`;
export const RECURLY_PARAM_ACCOUNT = 'account';
export const RECURLY_PARAM_PLAN = 'plan';
export const RECURLY_PUBLIC_KEY = 'ewr1-yohJduey7XMZYjBpTAXVRi';
export const RECURLY_DEVL_KEY = 'ewr1-PiJPyxgD1VKREe4mgQVJnL';

export const SIGNUP_EMAIL_URL = '/signup/email?email={email}';
export const ZIP_CODE_URL = '/signup/available/{postal_code}';
export const SIGNUP_URL = '/signup';
export const AVAILABLE_PLANS_URL = '/signup/available/plans';
export const REFRESH_CONTACT_URL = '/signup?contact_id={contact_id}';
export const UNSUBSCRIBE_URL = '/unsubscribe';
export const UNSUBSCRIBE_PATHNAME = '/signup/unsubscribe';

// NEW Signup App Constants
export const ZIPCODE_CHECK_URL = '/signup/{postal_code}';

export interface PackageConfiguration {
  type: string;
  name: string;
  features: string[];
}

export const PACKAGE_CONFIGURATIONS: PackageConfiguration[] = [
  {
    type: 'es',
    name: 'Essentials',
    features: [
      'Initial home appointment, and four (quarterly) appointments throughout the year',
      'Cataloging home details and creating a digital home profile',
      'Registration of all major home products',
      'Preventive care maintenance inspection at every appointment'
    ]
  },
  {
    type: 'pr',
    name: 'Preferred',
    features: [
      'Everything in the Essentials Plan plus',
      'Annual soft wash cleaning of exterior cladding, eaves, and fascia',
      'Remove & clean window screens, and wash both sides of windows',
      'Annual gutter cleaning and adjustments of downspouts'
    ]
  }
];

// Regex
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PHONE_REGEX = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
export const PASSWORD_REGEX = /^(?=.{8,})(?=.*[a-zA-Z])(?=.*[0-9@#$%^&+=]).*$/i;

// Platforms
export enum HKPlatform {
  ANDROID = 'android',
  CAPACITOR = 'capacitor',
  DESKTOP = 'desktop',
  IOS = 'ios',
  MOBILE_WEB = 'mobileweb',
  IOS_BROWSER = 'iosbrowser',
  ANDROID_BROWSER = 'androidbrowser'
}

export enum HKEnvironment {
  LOCL = 'locl',
  STAG = 'stag',
  PROD = 'prod'
}

export enum HKVisit {
  Catalog = 'initial_catalog',
  Autumn = 'autumn',
  Spring = 'spring',
  Summer = 'summer',
  Winter = 'winter'
}

export enum HKScheduleType {
  NEW,
  RESCHEDULE,
  COMPLETED
}

export enum HomeAttributes {
  INITIAL_CATALOG = 'initial_catalog'
}

export enum CatalogStatus {
  COMPLETED = 'completed',
  SCHEDULED = 'scheduled',
  NOT_SCHEDULED = 'not_scheduled'
}

export enum HKBillingPeriod {
  MONTHLY = 'monthly',
  YEARLY = 'yearly'
}

export enum RecurlySubscriptionStatus {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
  PAUSED = 'paused',
  FUTURE = 'future',
  TERMINATED = 'terminated'
}

export interface State {
  name: string;
  abbreviation: string;
}

export interface ContactType {
  name: string;
  type: string;
}

interface Page {
  title: string;
  path: string;
  icon: string;
}

export const FULL_SERVICE_PAGES: Page[] = [
  { title: 'Dashboard', path: '/dashboard', icon: String(HomeIcon) },
  { title: 'Contacts', path: '/contacts', icon: String(ContactsIcon) },
  { title: 'Inventory', path: '/inventory', icon: String(InventoryIcon) },
  { title: 'Documents', path: '/documents', icon: String(DocumentsIcon) },
  {
    title: 'Warranty',
    path: '',
    icon: String(AvidWarrantyIcon)
  },
  { title: 'Account', path: '/account', icon: String(UserIcon) }
];

export const DIGITAL_PAGES: Page[] = [
  { title: 'Dashboard', path: '/dashboard', icon: String(HomeIcon) },
  { title: 'Contacts', path: '/contacts', icon: String(ContactsIcon) },
  { title: 'Inventory', path: '/inventory', icon: String(InventoryIcon) },
  { title: 'Documents', path: '/documents', icon: String(DocumentsIcon) },
  {
    title: 'Warranty',
    path: '',
    icon: String(AvidWarrantyIcon)
  },
  { title: 'Account', path: '/account', icon: String(UserIcon) }
];

export const GA_PAGE_TITLES = [
  { path: '/account', title: 'account' },
  { path: '/account/change', title: 'change-account-modal' },
  { path: '/account/edit/contact', title: 'edit-account-user-modal' },
  { path: '/account/edit/home', title: 'edit-account-home-modal' },
  { path: '/account/delete', title: 'delete-account-modal' },
  { path: '/appointment', title: 'appointment-details' },
  { path: '/appointment/task', title: 'appointment-task-modal' },
  { path: '/appointment/wizard', title: 'appointment-wizard-modal' },
  { path: '/catalog', title: 'catalog' },
  { path: '/catalog/complete', title: 'catalog-complete-modal' },
  { path: '/catalog/task', title: 'catalog-task-modal' },
  { path: '/checkpoint', title: 'checkpoint' },
  { path: '/concierge', title: 'concierge' },
  { path: '/contacts', title: 'contacts' },
  { path: '/contact/details', title: 'contact-details' },
  { path: '/contact/details/new', title: 'contact-details-new' },
  { path: '/dashboard', title: 'dashboard' },
  { path: '/documents', title: 'documents' },
  { path: '/documents/add', title: 'documents-add' },
  { path: '/documents/group/floorplans', title: 'documents-group-floorplans' },
  {
    path: '/documents/group/loan_documents',
    title: 'documents-group-loan-documents'
  },
  { path: '/documents/group/manuals', title: 'documents-group-manuals' },
  {
    path: '/documents/group/property_records',
    title: 'documents-group-property-records'
  },
  { path: '/documents/group/videos', title: 'documents-group-videos' },
  {
    path: '/documents/group/video_thumb',
    title: 'documents-group-video-thumb'
  },
  { path: '/documents/view', title: 'documents-view' },
  { path: '/forgot-password', title: 'forgot-password' },
  { path: '/help', title: 'help' },
  { path: '/initial', title: 'initial-appointment' },
  { path: '/inventory', title: 'inventory' },
  { path: '/inventory/group', title: 'inventory-group' },
  { path: '/inventory/group/product', title: 'inventory-group-product' },
  {
    path: '/inventory/group/product/add',
    title: 'inventory-group-product-add'
  },

  { path: '/login', title: 'login' },
  {
    path: '/onboarding/what-type-of-home-do-you-own',
    title: 'onboarding-what-type-of-home-do-you-own'
  },
  {
    path: '/onboarding/what-are-you-looking-to-get-out-of-this-app',
    title: 'onboarding-what-are-you-looking-to-get-out-of-this-app'
  },
  {
    path: '/onboarding/what-type-of-homeowner-are-you',
    title: 'onboarding-what-type-of-homeowner-are-you'
  },
  {
    path: '/onboarding/how-long-do-you-expect-to-stay',
    title: 'onboarding-how-long-do-you-expect-to-stay'
  },
  { path: '/renew', title: 'renew' },
  { path: '/renew/addons', title: 'renew-addons-modal' },
  { path: '/renew/checkout', title: 'renew-checkout-desktop' },
  { path: '/renew/checkout/mobile', title: 'renew-checkout-modal' },
  { path: '/renew/summary', title: 'renew-summary-modal' },
  { path: '/renew/confirmation', title: 'renew/confirmation' },
  { path: '/signup/plans', title: 'signup-plans' },
  { path: '/signup/create', title: 'signup-create' },
  { path: '/signup/recurly', title: 'signup-recurly' },
  { path: '/tutorial', title: 'tutorial' },
  { path: '/task/details', title: 'task-details' }
];

export const STATE_LIST: State[] = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District Of Columbia', abbreviation: 'DC' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' }
];

export const CONTACT_TYPE_LIST: ContactType[] = [
  { name: 'Agent', type: 'agent' },
  {
    name: 'Air Duct Cleaning Contractor',
    type: 'air_duct_cleaning_contractor'
  },
  { name: 'Appliance Repair Person', type: 'appliance_repairperson' },
  { name: 'Builder', type: 'builder' },
  { name: 'Carpenter', type: 'carpenter' },
  { name: 'Contractor', type: 'contractor' },
  { name: 'Concrete Services', type: 'concrete_services' },
  { name: 'Countertop Installer', type: 'countertop_installer' },
  { name: 'Decks Patios Contractor', type: 'decks_patios_contractor' },
  { name: 'Door Windows Contractor', type: 'door_windows_contractor' },
  { name: 'Drywaller', type: 'drywaller' },
  { name: 'Electrician', type: 'electrician' },
  { name: 'Fireplaces Chimneys', type: 'fireplaces_chimneys' },
  { name: 'Flooring Professional', type: 'flooring_professional' },
  { name: 'Garage Door Professional', type: 'garage_door_professional' },
  { name: 'Gutters Siding Professional', type: 'gutters_siding_professional' },
  { name: 'Handy Person', type: 'handyperson' },
  { name: 'HVAC Professional', type: 'hvac_professional' },
  { name: 'HomeKeep', type: 'homekeep' },
  { name: 'Home Insurance', type: 'home_insurance' },
  { name: 'Home Security', type: 'home_security' },
  {
    name: 'Insulation Ventilation Professional',
    type: 'insulation_ventilation_professional'
  },
  { name: 'Landscaper', type: 'landscaper' },
  { name: 'Masonry Contractor', type: 'masonry_contractor' },
  { name: 'Plumber', type: 'plumber' },
  { name: 'Tile Contractor', type: 'tile_contractor' },
  { name: 'Roofer', type: 'roofer' },
  { name: 'Fire Safety', type: 'fire_safety' },
  { name: 'House Cleaner', type: 'house_cleaner' },
  { name: 'Radon Mitigation Service', type: 'radon_mitigation_service' },
  { name: 'Pest Control', type: 'pest_control' },
  { name: 'Utilities', type: 'utilities' }
];

export const HOMEKEEP_CONTACT: ContactInfo = {
  address1: null,
  address2: null,
  city: null,
  country: null,
  email: 'support@homekeep.com',
  home_phone: null,
  id: HOMEKEEP_CONTACT_ID,
  image: null,
  image_id: null,
  label: 'HomeKeep',
  mobile_phone: '(608) 719-4899',
  name: 'HomeKeep Concierge',
  postal_code: null,
  state: null,
  type: 'homekeep',
  type_id: HOMEKEEP_CONTACT_TYPE_ID,
  url: 'https://homekeep.com/contact-us',
  work_phone: null
};

export const TASK_IMAGES_LIST = [
  { type: 'washer', image: Washer },
  { type: 'dryer', image: Dryer },
  { type: 'furnace', image: Furnace },
  { type: 'hvac', image: Hvac },
  { type: 'humidifier', image: Humidifier },
  { type: 'refrigerator', image: Refrigerator },
  { type: 'water_softener', image: WaterSoftener },
  { type: 'smoke_detector', image: SmokeDetector },
  { type: 'carbon_monoxide', image: CarbonMonoxide },
  { type: 'radon_detection', image: RadonDetection },
  { type: 'fire_extinguisher', image: FireExtinguisher },
  { type: 'garbage_disposal', image: GarbageDisposal },
  { type: 'bath_fan', image: BathFan },
  { type: 'range', image: Range },
  { type: 'dishwasher', image: Dishwasher },
  { type: 'freezer', image: Freezer },
  { type: 'water_heater', image: WaterHeater },
  { type: 'electrical_breakers', image: ElectricalBreakers },
  { type: 'gutters_downspouts', image: GuttersDownspouts },
  { type: 'fireplace', image: Fireplace },
  { type: 'garage_door', image: GarageDoor },
  { type: 'garage_opener', image: GarageOpener },
  { type: 'air_conditioner', image: AirConditioner },
  { type: 'sump_pump', image: SumpPump },
  { type: 'doors', image: Doors },
  { type: 'windows', image: Windows },
  { type: 'water_valves', image: WaterValves },
  { type: 'roof', image: Roof },
  { type: 'cladding', image: Cladding },
  { type: 'ceiling_fan', image: CeilingFan },
  { type: 'water_filter_system', image: WaterFilterSystem },
  { type: 'ductwork', image: Ductwork },
  { type: 'drains', image: Drains },
  { type: 'hose_waterlines', image: HoseWaterlines },
  { type: 'seals_caulking', image: SealsCaulking },
  { type: 'faucets', image: Faucets },
  { type: 'building_components', image: BuildingComponents },
  { type: 'doors_windows', image: DoorsWindows },
  { type: 'smart_devices', image: SmartDevices },
  { type: 'projects_winter', image: ProjectsWinter },
  { type: 'projects_spring', image: ProjectsSpring },
  { type: 'projects', image: Projects },
  { type: 'pest_control', image: PestControl },
  { type: 'mattresses', image: Mattresses },
  { type: 'landscaping', image: Landscaping },
  { type: 'garden_hose', image: GardenHose },
  { type: 'garage_maintenance', image: GarageMaintenance },
  { type: 'concrete', image: Concrete },
  { type: 'hood', image: Hood },
  { type: 'solar_panel', image: SolarPanel },
  { type: 'home_cosmetics', image: HomeCosmetics },
  { type: 'home_health_safety', image: HomeHealthSafety },
  { type: 'oven', image: Oven },
  { type: 'microwave', image: Microwave },
  { type: 'other', image: Other }
];

export const COMPARISON_GRID: any[] = [
  {
    title: 'Digital Home Cataloging',
    digital: String(Check),
    autoDelivery: String(Check),
    fullService: String(Check)
  },
  {
    title: 'Home Product Inventory',
    digital: String(Check),
    autoDelivery: String(Check),
    fullService: String(Check)
  },
  {
    title: 'Registered Products',
    digital: String(Check),
    autoDelivery: String(Check),
    fullService: String(Check)
  },
  {
    title: 'Product Manuals & Warranties',
    digital: String(Check),
    autoDelivery: String(Check),
    fullService: String(Check)
  },
  {
    title: 'Custom Maintenance Reminders',
    digital: String(Check),
    autoDelivery: String(Check),
    fullService: String(Check)
  },
  {
    title: 'Manufacturer Notices',
    digital: String(Check),
    autoDelivery: String(Check),
    fullService: String(Check)
  },
  {
    title: 'Contacts Directory',
    digital: String(Check),
    autoDelivery: String(Check),
    fullService: String(Check)
  },
  {
    title: 'Document Library',
    digital: String(Check),
    autoDelivery: String(Check),
    fullService: String(Check)
  },
  {
    title: 'Maintenance History',
    digital: String(Check),
    autoDelivery: String(Check),
    fullService: String(Check)
  },
  {
    title: 'Concierge & Support',
    digital: String(Check),
    autoDelivery: String(Check),
    fullService: String(Check)
  },
  {
    title: 'Consumables Subscription & Delivery',
    digital: String(Close),
    autoDelivery: String(Check),
    fullService: String(Check)
  },
  {
    title: 'Full Service Appointments (2x Year)',
    digital: String(Close),
    autoDelivery: String(Close),
    fullService: String(Check)
  }
];
