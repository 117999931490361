import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonToolbar
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './OnboardingQuestion1.scss';
import { useHistory } from 'react-router';
import LoadingIonList from 'core/layout/LoadingIonList';
import CompleteIcon from 'assets/icons/complete.svg';
import { useEffect, useState } from 'react';
import { saveHomeType } from 'features/shared/signup/SignupSlice';
import { gaBtnClickEvent } from 'core/util';

interface HomeType {
  title: string;
  description: string;
  type: string;
}

const homeTypes: HomeType[] = [
  {
    title: 'Single Family Home',
    description: `Single building that's set up to accomodate one family.`,
    type: 'single_family_home'
  },
  {
    title: 'Multi-Family Home',
    description: `Single building that’s set up to accommodate more than one family.`,
    type: 'multi_family_home'
  },
  {
    title: 'Condo',
    description: `Housing complex in which there are separate units, owned by an individual.`,
    type: 'condo'
  },
  {
    title: 'Co-Op',
    description: `Housing owned by a corporation made up of the owners within the co-op.`,
    type: 'coop'
  },
  {
    title: 'Townhouse',
    description: `Multi-floor home that share one to two walls with adjacent properties.`,
    type: 'townhouse'
  }
];

const OnboardingQuestion1: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { home_type } = useSelector((state: RootState) => state.signup);
  const [selectedHomeType, setSelectedHomeType] = useState<string>('');

  const onNext = () => {
    gaBtnClickEvent('next_onboarding_1');
    dispatch(saveHomeType(selectedHomeType));
    history.push(`/onboarding/what-are-you-looking-to-get-out-of-this-app`);
  };

  useEffect(() => {
    if (home_type !== null) {
      setSelectedHomeType(home_type);
    }
  }, [home_type]);

  function DesktopOnboardingView() {
    return (
      <IonPage>
        <div className="desktop-onboarding-1-header-container">
          <div className="desktop-onboarding-1-header">
            What type of home do you own?
          </div>
          <div className="desktop-onboarding-1-subheader">
            We use this to help tailor tasks to your home type.
          </div>
        </div>
        <IonContent
          className="desktop-onboarding-1-view ion-padding"
          fullscreen
          scrollY={true}
        >
          <LoadingIonList
            className="desktop-onboarding-1-view-list"
            label="Home Type"
            items={homeTypes}
            loading={false}
          >
            {homeTypes.map((home: HomeType, i: number) => {
              const { title, description, type } = home;
              return (
                <div
                  key={`home-type-${i}`}
                  className={`desktop-onboarding-1-view-card ${
                    selectedHomeType === type &&
                    'desktop-onboarding-view-card-selected'
                  }`}
                  onClick={() => setSelectedHomeType(type)}
                >
                  <div className="desktop-onboarding-1-view-card-body">
                    <div className="desktop-onboarding-1-view-card-body-title">
                      {title}
                    </div>
                    <div className="desktop-onboarding-1-view-card-body-description">
                      {description}
                    </div>
                  </div>
                  <div className="desktop-onboarding-1-view-card-checkmark">
                    {selectedHomeType === type ? (
                      <IonIcon color="primary" icon={CompleteIcon} />
                    ) : (
                      <div className="desktop-onboarding-1-view-card-checkmark-circle"></div>
                    )}
                  </div>
                </div>
              );
            })}
          </LoadingIonList>
          <div className="desktop-onboarding-1-button-container">
            <IonButton
              className="desktop-onboarding-1-button"
              color="primary"
              type="button"
              disabled={!selectedHomeType}
              onClick={() => onNext()}
            >
              Next
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    );
  }

  function MobileOnboardingView() {
    return (
      <IonPage>
        <div className="onboarding-1-header-container">
          <h3 className="onboarding-1-header">
            <b>What type of home do you own?</b>
          </h3>
          <h6 className="onboarding-1-subheader">
            We use this to help tailer tasks to your home type.
          </h6>
        </div>
        <IonContent
          className="onboarding-1-view ion-padding"
          fullscreen
          scrollY={true}
        >
          <LoadingIonList
            className="onboarding-1-view-list"
            label="Home Type"
            items={homeTypes}
            loading={false}
          >
            {homeTypes.map((home: HomeType, i: number) => {
              const { title, description, type } = home;
              return (
                <div
                  key={`home-type-${i}`}
                  className={`onboarding-1-view-card ${
                    selectedHomeType === type &&
                    'onboarding-1-view-card-selected'
                  }`}
                  onClick={() => setSelectedHomeType(type)}
                >
                  <div className="onboarding-1-view-card-body">
                    <div className="onboarding-1-view-card-body-title">
                      {title}
                    </div>
                    <div className="onboarding-1-view-card-body-description">
                      {description}
                    </div>
                  </div>
                  <div className="onboarding-1-view-card-checkmark">
                    {selectedHomeType === type ? (
                      <IonIcon color="primary" icon={CompleteIcon} />
                    ) : (
                      <div className="onboarding-1-view-card-checkmark-circle"></div>
                    )}
                  </div>
                </div>
              );
            })}
          </LoadingIonList>
        </IonContent>
        <IonToolbar className="onboarding-1-toolbar">
          <IonButton
            className="onboarding-1-button"
            color="primary"
            type="button"
            disabled={!selectedHomeType}
            onClick={() => onNext()}
          >
            Next
          </IonButton>
        </IonToolbar>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopOnboardingView()
        : MobileOnboardingView()}
    </>
  );
};

export default OnboardingQuestion1;
