import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonToolbar
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Close from 'assets/icons/close.svg';
import './DocumentsAdd.scss';
import { hideDocumentsAddModal } from 'features/shared/documents/DocumentsSlice';
import { fetchDocumentTypes } from 'features/shared/documents/DocumentsActions';
import { DocumentType } from 'core/types';
import { gaBtnClickEvent, gaModalView } from 'core/util';
import UploadBox, {
  UploadBoxCancelUpload
} from 'components/upload-box/UploadBox';

const DocumentsAdd: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { types, filesUploading } = useSelector(
    (state: RootState) => state.documents
  );
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    string | null
  >('images');
  const { isDigital } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [showCancelUploads, setShowCancelUploads] = useState(false);

  // Create a ref to access UploadBox's methods
  const uploadBoxRef = useRef<UploadBoxCancelUpload>(null);

  // Function to handle the parent's click to cancel upload
  const handleCancelClick = () => {
    if (uploadBoxRef.current) {
      uploadBoxRef.current.handleCancelUpload();
      dispatch(hideDocumentsAddModal());
    }
  };

  const closeAddDocument = () => {
    gaBtnClickEvent('close_add_document');
    dispatch(hideDocumentsAddModal());
  };

  useEffect(() => {
    if (!types) {
      dispatch(fetchDocumentTypes());
    }
  }, [types]);

  useEffect(() => {
    gaModalView(isDigital, '/documents/add');
  }, []);

  const CancelUploadOverlay = () => {
    return (
      <div className="hk-cancel-upload-overlay">
        {!isDesktopWidth && (
          <IonFab vertical="top" horizontal="end">
            <IonFabButton
              color="background"
              className={`hk-fab-button hk-fab-button-margin-top`}
              onClick={() => setShowCancelUploads(false)}
            >
              <IonIcon color="white" icon={Close} />
            </IonFabButton>
          </IonFab>
        )}
        <div>
          <h1>Cancel Upload(s)?</h1>
          <p>Closing this modal will cancel the uploads in progress.</p>
        </div>

        <div className="hk-cancel-upload-overlay-buttons">
          <IonButton
            className="hk-cancel-upload-overlay-cancel-button"
            onClick={() => setShowCancelUploads(false)}
          >
            Cancel
          </IonButton>
          <IonButton
            className="hk-cancel-upload-overlay-cancel-uploads-button"
            color="danger"
            onClick={() => handleCancelClick()}
          >
            Cancel Uploads
          </IonButton>
        </div>
      </div>
    );
  };

  function DesktopDocumentsAddView() {
    return (
      <>
        {showCancelUploads && filesUploading && <CancelUploadOverlay />}
        <IonContent className="hk-desktop-digital-add-document">
          <div
            className="hk-desktop-digital-add-document-header"
            style={{
              background: `#353a4d`
            }}
          >
            <div className="hk-desktop-digital-add-document-header-title">
              Add Document
            </div>
            <IonFab vertical="top" horizontal="end">
              <IonFabButton
                color="background"
                className={`hk-fab-button ${
                  platformType === HKPlatform.DESKTOP && isDesktopWidth
                    ? ''
                    : 'hk-fab-button-margin-top'
                }`}
                onClick={() =>
                  !filesUploading
                    ? closeAddDocument()
                    : setShowCancelUploads(true)
                }
              >
                <IonIcon color="white" icon={Close} />
              </IonFabButton>
            </IonFab>
          </div>
          <div className="hk-desktop-digital-add-document-body">
            <div className="hk-desktop-digital-add-document-body-title">
              Choose what <strong>folder</strong> you want to upload to. Then{' '}
              {` `}
              <strong>drag and drop or choose one or more files</strong> to
              upload.
            </div>
            <IonSelect
              id="document-popover"
              className="hk-desktop-digital-add-document-body-document-types"
              interface="popover"
              interfaceOptions={{ alignment: 'start', side: 'bottom' }}
              placeholder="Select folder"
              value={selectedDocumentType!}
              onIonChange={(e) => setSelectedDocumentType(e.detail.value)}
            >
              {types.map((documentType: DocumentType, i: number) => {
                return (
                  <IonSelectOption
                    key={`document-type-${i}`}
                    value={documentType.field}
                  >
                    {documentType.label}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
            <UploadBox ref={uploadBoxRef} type={selectedDocumentType!} />
          </div>
        </IonContent>
        <div className="hk-desktop-digital-add-document-toolbar">
          <IonButton
            expand="block"
            color="primary"
            type="submit"
            strong={true}
            disabled={filesUploading}
            onClick={closeAddDocument}
          >
            {filesUploading ? 'Uploading' : 'Done'}
          </IonButton>
        </div>
      </>
    );
  }

  function MobileDocumentsAddView() {
    return (
      <>
        {showCancelUploads && filesUploading && <CancelUploadOverlay />}
        <IonContent className="hk-digital-add-document">
          <div
            className="hk-digital-add-document-header"
            style={{
              background: `#353a4d`
            }}
          >
            <div className="hk-digital-add-document-header-title">
              Add Document
            </div>
            <IonFab vertical="top" horizontal="end">
              <IonFabButton
                color="background"
                className={`hk-fab-button ${
                  platformType === HKPlatform.DESKTOP && isDesktopWidth
                    ? ''
                    : 'hk-fab-button-margin-top'
                }`}
                onClick={() =>
                  !filesUploading
                    ? closeAddDocument()
                    : setShowCancelUploads(true)
                }
              >
                <IonIcon color="white" icon={Close} />
              </IonFabButton>
            </IonFab>
          </div>
          <div className="hk-digital-add-document-body">
            <div className="hk-digital-add-document-body-title">
              Choose what <strong>folder</strong> you want to upload to. Then{' '}
              {` `}
              <strong>choose one or more files</strong> to upload.
            </div>
            <IonSelect
              id="document-popover"
              className="hk-digital-add-document-body-document-types"
              interface="popover"
              interfaceOptions={{ alignment: 'start', side: 'bottom' }}
              placeholder="Select folder"
              value={selectedDocumentType!}
              onIonChange={(e) => setSelectedDocumentType(e.detail.value)}
            >
              {types.map((documentType: DocumentType, i: number) => {
                return (
                  <IonSelectOption
                    key={`document-type-${i}`}
                    value={documentType.field}
                  >
                    {documentType.label}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
            <UploadBox ref={uploadBoxRef} type={selectedDocumentType!} />
          </div>
        </IonContent>
        <IonToolbar className="hk-digital-add-document-toolbar">
          <IonButton
            expand="block"
            color="primary"
            type="submit"
            strong={true}
            disabled={filesUploading}
            onClick={closeAddDocument}
          >
            {filesUploading ? 'Uploading' : 'Done'}
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDocumentsAddView()
        : MobileDocumentsAddView()}
    </>
  );
};

export default DocumentsAdd;
