import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import PlatformListener from './core/services/PlatformListener';
import Routes from 'core/routes/routes';
import store from './core/store';
import AlertContainer from './core/layout/AlertContainer';
import MobileWebRedirect from 'core/layout/mobile-web-redirect/MobileWebRedirect';
import DesktopNavigation from 'core/layout/desktop-navigation/DesktopNavigation';
import TermsAndConditions from 'core/layout/terms-and-conditions/TermsAndConditions';
import { firebaseConfig } from 'core/constants';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Device } from '@capacitor/device';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';

setupIonicReact({
  mode: 'md',
  scrollAssist: false,
  scrollPadding: false
});

const App: React.FC = () => {
  useEffect(() => {
    const init = async () => {
      if ((await Device.getInfo()).platform === 'web') {
        FirebaseAnalytics.initializeFirebase(firebaseConfig);
      }
    };
    init();
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <Provider store={store}>
          <PlatformListener>
            <DesktopNavigation>
              <Routes />
            </DesktopNavigation>
            <AlertContainer />
            <MobileWebRedirect />
            <TermsAndConditions />
          </PlatformListener>
        </Provider>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
