import {
  DATE_FORMAT_FULL_MONTH,
  DATE_FORMAT_FULL_MONTH_YEAR,
  DATE_FORMAT_MONTH_DATE,
  HKPlatform,
  TASK_IMAGES_LIST
} from 'core/constants';
import { RootState } from 'core/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DigitalDashboard.scss';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner
} from '@ionic/react';
import MenuHeader from 'core/layout/menu-header/MenuHeader';
import HomeDigitalIcon from 'assets/icons/home-digital.svg';
import LoadingIonList from 'core/layout/LoadingIonList';
import { useHistory } from 'react-router';
import {
  daysUntilRenewal,
  formatSimpleDate,
  gaBtnClickEvent,
  gaToggle,
  loadFirebaseTokenFromStorage,
  formatTimezoneDate
} from 'core/util';
import { registerForPushNotifications } from 'core/services/PushNotificatons';
import {
  hideProductTypeTogglesModal,
  hideTaskDetailsModal,
  resetDigitalTasksList,
  showProductTypeTogglesModal,
  showTaskDetailsModal
} from 'features/digital/store/DigitalTasksSlice';
import Illustration from 'components/Illustrations/Illustration';
import TaskCard from 'components/cards/task/TaskCard';
import { DigitalTask } from 'core/types';
import {
  fetchOverdueTasks,
  fetchCompletedTasks,
  fetchUpcomingTasks
} from 'features/digital/store/DigitalTasksActions';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import TasksEmptyImg from 'assets/illustrations/tasks-empty.svg';
import GoToArrow from 'assets/icons/go-to-arrow.svg';
import SettingsIcon from 'assets/icons/settings.svg';
import DigitalTaskDetails from '../tasks/DigitalMonthlyTaskDetails';
import DashboardAccordion from 'components/accordion/dashboard/DashboardAccordion';
import { format } from 'date-fns';
import DigitalDashboardSkeletonLoader from './DigitalDashboardSkeletonLoader';
import DigitalDashboardSettings from './DigitalDashboardSettings';
import HomeSwitcher from 'components/home-switcher/HomeSwitcher';

const DigitalDashboard: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { currentHome, globalAccountSettings } = useSelector(
    (state: RootState) => state.home
  );
  const { name } = useSelector((state: RootState) => state.user);
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { homes } = useSelector((state: RootState) => state.home);
  const {
    loading,
    upcomingTasks,
    completedTasks,
    modalTaskDetailsVisible,
    modalProductTypeTogglesVisible
  } = useSelector((state: RootState) => state.digitalTasks);
  const [isTitleVisible, setIsTitleVisible] = useState(true);
  const [isMounted, setIsMounted] = useState(true);

  const { catalogTasksList } = useSelector(
    (state: RootState) => state.digitalCatalog
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleOptions = ['Available', 'Completed'];
  const [toggleIndex, setToggleIndex] = useState(0);
  const [pageLoad, setPageLoad] = useState(false);

  const handleScroll = (event: any) => {
    if (event.detail.scrollTop > 0) {
      setIsTitleVisible(false);
    } else {
      setIsTitleVisible(true);
    }
  };

  const fetchAllTaskData = async () => {
    setPageLoad(true);
    dispatch(resetDigitalTasksList());
    if (!!homeId) {
      await dispatch(fetchOverdueTasks(homeId));
      await dispatch(fetchCompletedTasks(homeId));
      await dispatch(fetchUpcomingTasks(homeId));
    }
    setPageLoad(false);
  };

  const getListOfMonthYear = (tasks: DigitalTask[]) => {
    let sortedTasks = [...tasks];
    let listOfMonths: any[] = [];
    let uniqueListOfMonths = new Set();
    sortedTasks.sort(function (a, b) {
      return new Date(a.scheduled).getTime() - new Date(b.scheduled).getTime();
    });
    sortedTasks.forEach((task) => {
      const month = formatTimezoneDate(
        task.scheduled,
        DATE_FORMAT_FULL_MONTH_YEAR
      );
      uniqueListOfMonths.add(month);
    });
    listOfMonths = Array.from(uniqueListOfMonths);
    // If all tasks for current month are completed, insert current month placeholder
    const currentMonth = format(new Date(), DATE_FORMAT_FULL_MONTH_YEAR);
    if (!listOfMonths.includes(currentMonth)) {
      listOfMonths.unshift(currentMonth);
    }
    return listOfMonths;
  };

  const getTasksByMonth = (tasks: DigitalTask[], month: string) => {
    let listOfTasksByMonth: any[] = [];
    tasks.forEach((task) => {
      const taskMonth = formatTimezoneDate(
        task.scheduled,
        DATE_FORMAT_FULL_MONTH_YEAR
      );
      if (taskMonth === month) {
        listOfTasksByMonth.push(task);
      }
    });
    return listOfTasksByMonth;
  };

  const getTaskImage = (taskType: string) => {
    return TASK_IMAGES_LIST.find((task) => task.type === taskType)?.image;
  };

  const isCurrentMonth = (month: string) => {
    const todayMonth = new Date().toLocaleString('default', {
      month: 'long',
      year: 'numeric'
    });
    return todayMonth === month;
  };

  const isTaskAvailable = (date: any) => {
    const currentTaskMonth = formatTimezoneDate(date, DATE_FORMAT_FULL_MONTH);
    const today = new Date();
    return (
      today.toLocaleString('default', { month: 'long' }) === currentTaskMonth
    );
  };

  const openTask = (id: number) => {
    gaBtnClickEvent('open_task_' + id);
    dispatch(showTaskDetailsModal(id));
  };

  const closeTask = () => {
    fetchAllTaskData();
    dispatch(hideTaskDetailsModal());
    dispatch(hideProductTypeTogglesModal());
  };

  const openProductTypeSettings = () => {
    gaBtnClickEvent('open_product_type_toggles_settings');
    dispatch(showProductTypeTogglesModal());
  };

  const isCatalogCompleted = () => {
    const completedTasksList = catalogTasksList.filter(
      (task) => task.finished !== null
    );
    // We will display the prompt until the user completes 50% or more of the catalog tasks
    return completedTasksList.length < catalogTasksList.length / 2;
  };

  const allMonthlyTasksCompleted = (month: string) => {
    return !upcomingTasks.some(
      (task: any) =>
        formatTimezoneDate(task.scheduled, DATE_FORMAT_FULL_MONTH_YEAR) ===
        month
    );
  };

  const redirectToHomeProfile = () => {
    history.push(`/catalog`);
    gaBtnClickEvent('next_home_profile');
  };

  const redirectToDocument = () => {
    history.push(`/documents`);
    gaBtnClickEvent('next_add_document');
  };

  const goToExpired = () => {
    history.push(`/renew`);
  };

  useEffect(() => {
    if (isMounted) {
      fetchAllTaskData();
    }
    return () => {
      setIsMounted(false);
    };
  }, [dispatch, homeId]);

  useEffect(() => {
    loadFirebaseTokenFromStorage().then((token) => {
      if (!token) {
        registerForPushNotifications();
      }
    });
  });

  useEffect(() => {
    gaToggle(globalAccountSettings?.impersonating === null);
  }, []);

  function TasksEmpty() {
    return (
      <div className="tasks-list-empty">
        <section className="ion-text-center ion-margin-vertical">
          <Illustration image={TasksEmptyImg} description="TasksEmpty" />
        </section>
        <h3 className="ion-text-center">Something Went Wrong</h3>
        <p className="ion-padding-horizontal ion-text-center">
          Content wasn't able to load, try restarting the app to fix the issue
        </p>
      </div>
    );
  }

  function DesktopDigitalDashboardView() {
    return (
      <>
        {!!homes && homes.length > 1 && (
          <IonRow className="hk-desktop-digital-dashboard-home-switcher">
            <div className="hk-desktop-digital-dashboard-home-switcher-container">
              <div className="hk-desktop-digital-dashboard-home-switcher-title">
                HOME SWITCHER
              </div>
              <div className="hk-desktop-digital-dashboard-home-switcher-content">
                <HomeSwitcher />
              </div>
            </div>
          </IonRow>
        )}

        <IonRow className="hk-desktop-digital-dashboard-home">
          <div className="hk-desktop-digital-dashboard-home-info">
            <div className="hk-desktop-digital-dashboard-home-info-address">
              <img
                src={HomeDigitalIcon}
                className="hk-desktop-digital-dashboard-home-info-address-icon"
                alt={'Home'}
              />
              <span>{currentHome?.address1}</span>
            </div>
            <div className="hk-desktop-digital-dashboard-home-info-welcome">
              Welcome Home <span>{name}</span>
            </div>
          </div>
        </IonRow>
        <IonContent color="white">
          <div className="hk-desktop-digital-dashboard-content">
            {pageLoad ? (
              <div className="hk-loading-spinner">
                <IonSpinner name="crescent" color="primary" />
              </div>
            ) : (
              <>
                <div
                  className="hk-desktop-digital-dashboard-home-profile-card"
                  onClick={redirectToHomeProfile}
                >
                  <div className="hk-desktop-digital-dashboard-home-profile-card-text">
                    <p>Customize your tasks by updating your</p>
                    <h6>Home Profile</h6>
                  </div>
                  <IonIcon icon={GoToArrow} />
                </div>
                <IonRow className="hk-desktop-digital-dashboard-tasks-toggle">
                  <div className="hk-desktop-digital-dashboard-tasks-toggle-container">
                    {toggleOptions.map((opt, i) => {
                      return (
                        <div
                          key={`toggle-item-${i}`}
                          className={`toggle-pill
                          ${toggleIndex === i ? 'selected' : ''}
                          ${
                            !completedTasks.length && opt === 'Completed'
                              ? 'disabled'
                              : ''
                          }
                        `}
                          onClick={() => {
                            setToggleIndex(i);
                          }}
                        >
                          {opt}
                        </div>
                      );
                    })}
                  </div>

                  <IonIcon
                    icon={SettingsIcon}
                    className="hk-digital-dashboard-tasks-toggle-settings"
                    onClick={openProductTypeSettings}
                  />
                </IonRow>
                <SwitchTransition>
                  <CSSTransition
                    key={toggleIndex ? 'Monthly Tasks' : 'Completed Tasks'}
                    addEndListener={(node, done) =>
                      node.addEventListener('transitionend', done, false)
                    }
                    classNames="toggle"
                  >
                    <div
                      className={`hk-desktop-digital-dashboard-tasks-list-animation ${
                        toggleIndex === 0 ? 'left' : 'right'
                      }`}
                    >
                      <LoadingIonList
                        className="hk-desktop-digital-dashboard-tasks-list"
                        label={
                          toggleIndex ? 'Monthly Tasks' : 'Completed Tasks'
                        }
                        items={[toggleIndex]}
                        loading={loading}
                      >
                        {toggleIndex === 0 && (
                          <>
                            {getListOfMonthYear(upcomingTasks).map(
                              (month: any, i: any) => {
                                return (
                                  <div key={`upcoming-tasks-month-${i}`}>
                                    <DashboardAccordion
                                      label={'Tasks for ' + month}
                                      collapsed={!isCurrentMonth(month)}
                                    >
                                      {getTasksByMonth(
                                        upcomingTasks,
                                        month
                                      ).map((task: DigitalTask, i: any) => {
                                        return (
                                          <IonItem
                                            color="white"
                                            key={`upcoming-tasks-${i}`}
                                          >
                                            <TaskCard
                                              image={getTaskImage(
                                                task.product_type_name!
                                              )}
                                              product={task.product_type_label}
                                              description={task.name}
                                              available={isTaskAvailable(
                                                task.scheduled
                                              )}
                                              time={task.duration!}
                                              taskClicked={() =>
                                                openTask(task.id)
                                              }
                                            ></TaskCard>
                                          </IonItem>
                                        );
                                      })}
                                    </DashboardAccordion>

                                    {i === 0 &&
                                      isCatalogCompleted() &&
                                      allMonthlyTasksCompleted(month) && (
                                        <div className="hk-desktop-digital-dashboard-tasks-list-redirect">
                                          <h3>All Caught Up</h3>
                                          <p>
                                            Want to tailor these tasks more to
                                            your home? Complete your home
                                            profile.
                                          </p>
                                          <IonButton
                                            expand="block"
                                            color="primary"
                                            strong={true}
                                            onClick={redirectToHomeProfile}
                                          >
                                            Next: Home Profile
                                          </IonButton>
                                        </div>
                                      )}
                                    {i === 0 &&
                                      !isCatalogCompleted() &&
                                      allMonthlyTasksCompleted(month) && (
                                        <div className="hk-desktop-digital-dashboard-tasks-list-redirect">
                                          <h3>All Caught Up</h3>
                                          <p>
                                            We will notify you when the next
                                            month’s tasks are ready. In the
                                            meantime upload documents such as
                                            your floor plan or inspection
                                            report.
                                          </p>
                                          <IonButton
                                            expand="block"
                                            color="primary"
                                            strong={true}
                                            onClick={redirectToDocument}
                                          >
                                            Next: Add Document
                                          </IonButton>
                                        </div>
                                      )}
                                  </div>
                                );
                              }
                            )}
                            {!pageLoad &&
                              upcomingTasks.length === 0 &&
                              completedTasks.length === 0 && <TasksEmpty />}
                          </>
                        )}
                        {toggleIndex === 1 && (
                          <>
                            {getListOfMonthYear(completedTasks).map(
                              (month: any, i: any) => {
                                return (
                                  <div key={`completed-tasks-month-${i}`}>
                                    <DashboardAccordion
                                      label={'Tasks for ' + month}
                                      value={month}
                                      collapsed={true}
                                    >
                                      {getTasksByMonth(
                                        completedTasks,
                                        month
                                      ).map((task: DigitalTask, i: any) => {
                                        return (
                                          <IonItem
                                            color="white"
                                            key={`completed-tasks-${i}`}
                                          >
                                            <TaskCard
                                              image={getTaskImage(
                                                task.product_type_name!
                                              )}
                                              product={task.product_type_label}
                                              description={task.name}
                                              completed={true}
                                              available={!!task.scheduled}
                                              time={task.duration!}
                                              taskClicked={() =>
                                                openTask(task.id)
                                              }
                                            ></TaskCard>
                                          </IonItem>
                                        );
                                      })}
                                    </DashboardAccordion>
                                  </div>
                                );
                              }
                            )}
                            {completedTasks.length === 0 && <TasksEmpty />}
                          </>
                        )}
                      </LoadingIonList>
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </>
            )}
          </div>
        </IonContent>
      </>
    );
  }

  function MobileDigitalDashboardView() {
    return (
      <>
        <MenuHeader>
          <div
            className={`hk-digital-dashboard ${
              !isTitleVisible ? 'hk-hidden' : 'hk-show'
            }`}
          >
            <div
              className={`hk-digital-dashboard-home-info ${
                !isTitleVisible ? 'hk-hidden' : 'hk-show'
              }`}
            >
              <div
                className={`hk-digital-dashboard-home-info-address ${
                  !isTitleVisible ? 'hk-hidden' : 'hk-show'
                }`}
              >
                <img
                  src={HomeDigitalIcon}
                  className="hk-digital-dashboard-home-info-address-icon"
                  alt={'Home'}
                />
                <span>{currentHome?.address1}</span>
              </div>
              <div className="hk-digital-dashboard-home-info-welcome">
                Welcome Home <span>{name}</span>
              </div>
            </div>
          </div>
        </MenuHeader>
        <IonContent
          color="white"
          scrollEvents={true}
          onIonScroll={handleScroll}
        >
          {pageLoad ? (
            <DigitalDashboardSkeletonLoader />
          ) : (
            <>
              <div
                className="hk-digital-dashboard-home-profile-card"
                onClick={redirectToHomeProfile}
              >
                <div className="hk-digital-dashboard-home-profile-card-text">
                  <p>Customize your tasks by updating your</p>
                  <h6>Home Profile</h6>
                </div>
                <IonIcon icon={GoToArrow} />
              </div>
              <IonRow className="hk-digital-dashboard-tasks-toggle">
                <div className="hk-digital-dashboard-tasks-toggle-container">
                  {toggleOptions.map((opt, i) => {
                    return (
                      <div
                        key={`toggle-item-${i}`}
                        className={`toggle-pill
                      ${toggleIndex === i ? 'selected' : ''}
                      ${
                        !completedTasks.length && opt === 'Completed'
                          ? 'disabled'
                          : ''
                      }
                                    `}
                        onClick={() => {
                          setToggleIndex(i);
                        }}
                      >
                        {opt}
                      </div>
                    );
                  })}
                </div>

                <IonIcon
                  icon={SettingsIcon}
                  className="hk-digital-dashboard-tasks-toggle-settings"
                  onClick={openProductTypeSettings}
                />
              </IonRow>
              <SwitchTransition>
                <CSSTransition
                  key={toggleIndex ? 'Monthly Tasks' : 'Completed Tasks'}
                  addEndListener={(node, done) =>
                    node.addEventListener('transitionend', done, false)
                  }
                  classNames="toggle"
                >
                  <div
                    className={`hk-digital-dashboard-tasks-list-animation ${
                      toggleIndex === 0 ? 'left' : 'right'
                    }`}
                  >
                    <LoadingIonList
                      className="hk-digital-dashboard-tasks-list"
                      label={toggleIndex ? 'Monthly Tasks' : 'Completed Tasks'}
                      items={[toggleIndex]}
                      loading={loading}
                    >
                      {toggleIndex === 0 && (
                        <>
                          {getListOfMonthYear(upcomingTasks).map(
                            (month: any, i: any) => {
                              return (
                                <div key={`upcoming-tasks-month-${i}`}>
                                  <DashboardAccordion
                                    label={'Tasks for ' + month}
                                    collapsed={!isCurrentMonth(month)}
                                  >
                                    {getTasksByMonth(upcomingTasks, month).map(
                                      (task: DigitalTask, i: any) => {
                                        return (
                                          <IonItem
                                            color="white"
                                            key={`upcoming-tasks-${i}`}
                                          >
                                            <TaskCard
                                              image={getTaskImage(
                                                task.product_type_name!
                                              )}
                                              product={task.product_type_label}
                                              description={task.name}
                                              available={isTaskAvailable(
                                                task.scheduled
                                              )}
                                              time={task.duration!}
                                              taskClicked={() =>
                                                openTask(task.id)
                                              }
                                            ></TaskCard>
                                          </IonItem>
                                        );
                                      }
                                    )}
                                  </DashboardAccordion>
                                  {getListOfMonthYear(upcomingTasks).length !==
                                    i + 1 && (
                                    <div className="hk-dashboard-accordion-divider" />
                                  )}

                                  {i === 0 &&
                                    isCatalogCompleted() &&
                                    allMonthlyTasksCompleted(month) && (
                                      <div className="hk-digital-dashboard-tasks-list-redirect">
                                        <h3>All Caught Up</h3>
                                        <p>
                                          Want to tailor these tasks more to
                                          your home? Complete your home profile.
                                        </p>
                                        <IonButton
                                          expand="block"
                                          color="primary"
                                          strong={true}
                                          onClick={redirectToHomeProfile}
                                        >
                                          Next: Home Profile
                                        </IonButton>
                                      </div>
                                    )}
                                  {i === 0 &&
                                    !isCatalogCompleted() &&
                                    allMonthlyTasksCompleted(month) && (
                                      <div className="hk-digital-dashboard-tasks-list-redirect">
                                        <h3>All Caught Up</h3>
                                        <p>
                                          We will notify you when the next
                                          month’s tasks are ready. In the
                                          meantime upload documents such as your
                                          floor plan or inspection report.
                                        </p>
                                        <IonButton
                                          expand="block"
                                          color="primary"
                                          strong={true}
                                          onClick={redirectToDocument}
                                        >
                                          Next: Add Document
                                        </IonButton>
                                      </div>
                                    )}
                                </div>
                              );
                            }
                          )}
                          {!pageLoad &&
                            upcomingTasks.length === 0 &&
                            completedTasks.length === 0 && <TasksEmpty />}
                        </>
                      )}
                      {toggleIndex === 1 && (
                        <>
                          {getListOfMonthYear(completedTasks).map(
                            (month: any, i: any) => {
                              return (
                                <div key={`completed-tasks-month-${i}`}>
                                  <DashboardAccordion
                                    label={'Tasks for ' + month}
                                    value={month}
                                    collapsed={true}
                                  >
                                    {getTasksByMonth(completedTasks, month).map(
                                      (task: DigitalTask, i: any) => {
                                        return (
                                          <IonItem
                                            color="white"
                                            key={`completed-tasks-${i}`}
                                          >
                                            <TaskCard
                                              image={getTaskImage(
                                                task.product_type_name!
                                              )}
                                              product={task.product_type_label}
                                              description={task.name}
                                              completed={true}
                                              available={!!task.scheduled}
                                              time={task.duration!}
                                              taskClicked={() =>
                                                openTask(task.id)
                                              }
                                            ></TaskCard>
                                          </IonItem>
                                        );
                                      }
                                    )}
                                  </DashboardAccordion>
                                  {getListOfMonthYear(completedTasks).length !==
                                    i + 1 && (
                                    <div className="hk-dashboard-accordion-divider" />
                                  )}
                                </div>
                              );
                            }
                          )}
                          {completedTasks.length === 0 && <TasksEmpty />}
                        </>
                      )}
                    </LoadingIonList>
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </>
          )}
        </IonContent>
      </>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-digital-dashboard-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDigitalDashboardView()
        : MobileDigitalDashboardView()}
      <IonModal
        className="hk-digital-task-modal"
        isOpen={modalTaskDetailsVisible}
        onDidDismiss={() => {
          closeTask();
        }}
      >
        <DigitalTaskDetails />
      </IonModal>
      <IonModal
        className="hk-product-type-toggles-modal"
        isOpen={modalProductTypeTogglesVisible}
        onDidDismiss={() => {
          closeTask();
        }}
      >
        <DigitalDashboardSettings />
      </IonModal>
    </IonPage>
  );
};

export default DigitalDashboard;
