import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { daysUntilRenewal } from 'core/util';
import { WARRANTY_BASE } from '../constants';

export interface Home {
  id: number;
  name: string;
  catalog_status: string;
  description: string;
  image: string;
  image_id: number;
  address1: string;
  address2: string;
  city: string;
  postal_code: string;
  market_timezone: string;
  state: string;
  created: string;
  subscriptions: Subscription[];
  type: string;
  type_id: number;
  is_cataloged: boolean;
  is_diy: boolean;
}

export interface Subscription {
  name: string;
  id: number;
}

export interface GlobalAccountSettings {
  admin: boolean;
  auto_renew: boolean | null;
  auto_renew_next: string | null;
  builder_id: number | null;
  demo: boolean;
  expired: boolean;
  expires: null | string;
  home_id: number;
  impersonating: null | any;
  plan: string;
  user_id: number;
  avid_warranty_url: string | null;
  recurly_status: string;
}

interface HomeState {
  homes: Home[];
  builderLogo: string;
  currentHome: Home | null;
  globalAccountSettings: GlobalAccountSettings | null;
  isExpired: boolean | null;
  isEligibleRenewal: boolean | null;
  warrantyURL: string | null;
}

export const initialState: HomeState = {
  homes: [],
  builderLogo: '',
  currentHome: null,
  globalAccountSettings: null,
  isExpired: null,
  isEligibleRenewal: null,
  warrantyURL: null
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHomes: (homeState, action: PayloadAction<any>) => {
      homeState.homes = action.payload;
    },
    setBuilderLogo: (homeState, action: PayloadAction<string>) => {
      homeState.builderLogo = action.payload;
    },
    setCurrentHome: (homeState, action: PayloadAction<any>) => {
      homeState.currentHome = action.payload;
    },
    setGlobalAccountSettings: (homeState, action: PayloadAction<any>) => {
      homeState.globalAccountSettings = action.payload;
    },
    setIsExpired: (homeState, action: PayloadAction<any>) => {
      homeState.isExpired = action.payload;
    },
    setIsEligibleRenewal: (homeState, action: PayloadAction<any>) => {
      const renewalDate = action.payload;
      if (!!renewalDate && daysUntilRenewal(renewalDate) < 30) {
        homeState.isEligibleRenewal = true;
      } else {
        homeState.isEligibleRenewal = false;
      }
    },
    setWarrantyURL: (homeState, action: PayloadAction<string | null>) => {
      if (action.payload !== null) {
        homeState.warrantyURL = String(WARRANTY_BASE + action.payload);
      }
    },
    updateHomeSuccess: (homeState, action: PayloadAction<any>) => {
      const home = action.payload;
      const currentHome = homeState.homes?.find((h) => h.id === home.id);
      if (currentHome) {
        homeState.homes?.splice(homeState.homes.indexOf(currentHome), 1, home);
        homeState.currentHome = home;
      }
    },
    updateHomeFailure: (homeState, action: PayloadAction<any>) => {},
    deleteHomeSuccess: () => initialState,
    deleteHomeError: (homeState, action: PayloadAction<any>) => {},
    resetHomes: () => initialState
  }
});

export const {
  setHomes,
  setBuilderLogo,
  setCurrentHome,
  setGlobalAccountSettings,
  setIsExpired,
  setIsEligibleRenewal,
  updateHomeSuccess,
  updateHomeFailure,
  deleteHomeSuccess,
  deleteHomeError,
  resetHomes,
  setWarrantyURL
} = homeSlice.actions;

export default homeSlice.reducer;
